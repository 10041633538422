site.base =
    {
        jsonErrors: function (xhr, evt, status, form) {
            var content, value, _i, _len, _ref, $this, contentWrapper;
            $this = form;
            contentWrapper = $this.find('.json-error-wrapper');
            content = contentWrapper.find('#errors');
            content.find('ul').empty();
            _ref = $.parseJSON(xhr.responseText).errors;
            // Append errors to list on page
            for (_i = 0, _len = _ref.length; _i < _len; _i++) {
                value = _ref[_i];
                content.show().find('ul').append('<li><i class="fa fa-times-circle"></i>' + value + '</li>');
            }
            contentWrapper.show();
        },

        contactMessage: function () {
            $('body').on('submit', '#new_contact_message', function () {
                var url = $(this).attr('action'),
                    $this = $(this);
                $.ajax(
                    {
                        url: url,
                        type: 'POST',
                        data: $(this).serialize(),
                        dataType: 'json',
                        success: function (data) {
                            $this[0].submit();
                        },
                        error: function (xhr, evt, status) {
                            site.base.jsonErrors(xhr, evt, status, $this);
                            $([document.documentElement, document.body]).animate({
                                scrollTop: $("#contact").offset().top
                            }, 1000);
                        }
                    });
                return false;
            })
        },

        initCookies: function () {
            if (localStorage.getItem('cookieToastState') != 'shown') {
                $('#cookieToast').toast('show');
                localStorage.setItem('cookieToastState', 'shown')
            }
        }
    }